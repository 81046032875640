<template>
  <v-card flat>
    <v-card-title>
      {{ $capitalize($tc("model.report_visit_visit_report_title")) }}
      <v-spacer />
      <ScopeProvider scope="leads.visit_report.edit">
        <v-btn outlined color="primary" :loading="saving" @click="update">
          {{ $capitalize($tc("model.report_visit_save_button")) }}
        </v-btn>
      </ScopeProvider>
    </v-card-title>

    <ValidationObserver ref="visit_report_form">
      <v-card-title>
        <v-icon left> mdi-check-box-outline </v-icon>
        {{ $capitalize($tc("model.report_visit_framed_checkbox")) }}
      </v-card-title>
      <v-card-text>
        <ValidationProvider name="visit_report.is_matched" v-slot="{ errors }">
          <v-select
            v-model="form.is_matched"
            :label="$capitalize($tc('model.final_report_framing_title'))"
            :items="fitting_items"
            outlined
            :error-messages="errors"
          ></v-select>
        </ValidationProvider>
      </v-card-text>

      <v-card-title class="pt-0">
        <v-icon left>mdi-ray-start-arrow</v-icon>
        {{ __("origem/Causa", "report_visit") }}
      </v-card-title>
      <v-card-text class="mb-0">
        <ValidationProvider
          rules="required"
          name="visit_report.origin_type_id"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            v-model="form.origin_type_id"
            item-value="id"
            item-text="description"
            :items="available_origin_types"
            :loading="fecthing_available_origin_types"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-card-text>

      <v-card-title class="pt-0">
        <v-icon left>mdi-file-chart</v-icon>
        {{ $capitalize($tc("model.report_visit_report")) }}
      </v-card-title>
      <v-card-text class="mb-0">
        <ValidationProvider
          name="visit_report.origin_type_id"
          v-slot="{ errors }"
        >
          <v-textarea
            outlined
            :rows="10"
            auto-grow
            v-model="form.established_facts"
            :error-messages="errors"
          ></v-textarea>
        </ValidationProvider>
      </v-card-text>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { formatDateTime } from "@/tools/date";
import { update } from "@/services/visit_reports";

import { indexAvailableOriginTypes } from "@/services/lead";

import ScopeProvider from "@/components/ScopeProvider";
import i18n from "@/mixins/i18n";

export default {
  components: { ScopeProvider },
  mixins: [i18n],
  data: function () {
    return {
      form: {},

      saving: false,

      available_origin_types: [],
      fecthing_available_origin_types: false,

      fitting_items: [
        {
          text: this.$capitalize(this.$tc("model.visit_report_to_be_defined")),
          value: null,
        },
        {
          text: this.$capitalize(this.$tc("model.products_framed")),
          value: true,
        },
        {
          text: this.$capitalize(this.$tc("model.products_unframed")),
          value: false,
        },
      ],
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
  },
  methods: {
    async loadAvailableOriginTypes() {
      this.fecthing_available_origin_types = true;

      try {
        const response = await indexAvailableOriginTypes(
          this.$route.params["lead_number"]
        );
        this.available_origin_types = response;
      } finally {
        this.fecthing_available_origin_types = false;
      }
    },
    async update() {
      const validation = await this.$refs.visit_report_form.validate();
      if (!validation) return;

      this.saving = true;

      try {
        const response = await update(this.$route.params["lead_number"], {
          visit_report: this.form,
        });
        this.$store.commit("setLead", {
          ...this.lead,
          ...response,
        });
        this.$store.commit("sendMessage", {
          text: "Relatório atualizado com sucesso",
          color: "green",
        });
      } catch (e) {
        if (e?.response?.data?.errors) {
          this.$refs.visit_report_form.setErrors(e?.response?.data.errors);
        }
        if (e?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: e?.response?.data?.message,
            color: "red",
          });
        }
      } finally {
        this.saving = false;
      }
    },

    loadForm() {
      this.form = {
        origin_type_id: this.lead.origin_type_id,
        is_matched: this.lead.is_matched,
        origin_type_id: this.lead.origin_type_id,
        established_facts: this.lead.established_facts,
      };
    },
    formatDate(date) {
      if (!date) return "Sem data marcada";

      return formatDateTime(date);
    },
  },
  created() {
    this.loadAvailableOriginTypes();
    this.loadForm();
  },
  watch: {
    lead: function (value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.loadForm();
      }
    },
  },
};
</script>

<style></style>
